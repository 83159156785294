import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';
import logo from './logo.png';

export default function RequestReset() {

  const [values, setValues] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState("")
  const [confirmValues, setConfirmValues] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const navigate = useNavigate();

  const submit = async () => {
    try {
      await fetch(`${process.env.REACT_APP_API_URL}/auth/reset-password`, {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json"
        }),
        body: JSON.stringify({ email: values.email.toLowerCase() })
      })
        .then(res => {
          return res.json();
        })
        .then(x => {
          setSubmitted(true)
          if (x.error) {
            setError(error);
          }
        });
    } catch (err) {
      setError(err.message);
    }
  }

  const onConfirm = async () => {
    try {
      await fetch(`${process.env.REACT_APP_API_URL}/auth/verify-reset-code`, {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json"
        }),
        body: JSON.stringify({ email: values.email.toLowerCase(), resetCode: confirmValues.confirmationCode, newPassword: confirmValues.password })
      })
        .then(res => {
          return res.json();
        })
        .then(x => {
          if (x.error) {
            setError(x.error)
          } else {
            navigate('/login')
          }
        });

    } catch (err) {
      setError(err.message);
    }
  }

  const resendCode = async () => {
    try {
      await fetch(`${process.env.REACT_APP_API_URL}/auth/resend-reset-code`, {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json"
        }),
        body: JSON.stringify({ email: values.email.toLowerCase() })
      })
        .then(res => {
          return res.json();
        })
        .then(x => {
          if (x.error) {
            setError(x.error)
          }
        });
    } catch (err) {
      setError(err.message);
    }
  }

  return (
    <>
      <div className="flex min-h-screen flex-col bg-dark">
        <div className="flex flex-1 flex-col justify-center items-center ">
          <div className="sm:mx-auto sm:w-full sm:max-w-md cursor-pointer" onClick={() => navigate('/')}>
            <img
              className="mx-auto w-64 h-auto mb-10"
              src={logo}
            />
          </div>

          <div className="mt-4 sm:mx-auto sm:w-full sm:max-w-lg">
            <div className="bg-neutral-700 rounded py-8 px-4 shadow sm:px-10">
              <div className="font-medium text-xl text-white text-center">Reset Password</div>
              <div className="font-light text-sm text-light text-center my-5">
                Have an account? <a href="/login" className="font-medium text-purple-300 hover:text-purple-400">
                  login
                </a>
              </div>
              {!submitted &&
                <div>
                  <div className="space-y-6">
                    <div>
                      <label className="block text-sm font-medium text-red-400 text-center">
                        {error}
                      </label>
                    </div>
                    <div>
                      <label htmlFor="email" className="block text-sm font-medium text-white">
                        Email address
                      </label>
                      <div className="mt-1">
                        <input value={values.email} onChange={(e) => setValues({ ...values, email: e.target.value })}
                          id="email"
                          name="email"
                          type="email"
                          autoComplete="email"
                          required
                          className="block w-full px-3 py-2 placeholder-purple-400 border border-purple-300 rounded shadow-sm appearance-none focus:border-purple-500 focus:outline-none focus:ring-purple-500 sm:text-sm"
                        />
                      </div>
                    </div>
                  </div>

                  <div className='mt-4'>
                    <button
                      onClick={() => submit()}
                      type="button"
                      className="flex justify-center w-full px-4 py-2 text-sm font-medium text-white bg-purple-500 border border-transparent rounded shadow-sm hover:bg-purple-500 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              }
              {submitted &&
                <div className='flex flex-col gap-5'>
                  <div>
                    <div className='flex justify-between'>
                      <label
                        htmlFor="name"
                        className="block text-sm font-medium text-white"
                      >
                        Verification Code <span className="text-red-500">*</span>
                      </label>
                      <div className="block text-md font-bold text-white cursor-pointer" onClick={() => resendCode()}>Resend Code</div>
                    </div>
                    <div className="mt-1">
                      <input
                        value={confirmValues.confirmationCode}
                        onChange={e =>
                          setConfirmValues({ ...confirmValues, confirmationCode: e.target.value })
                        }
                        id="confirmationCode"
                        name="confirmationCode"
                        autoComplete="confirmationCode"
                        required
                        className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded shadow-sm appearance-none focus:border-gray-500 focus:outline-none focus:ring-gray-500 sm:text-sm"
                      />
                    </div>
                  </div>
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <label
                        htmlFor="password"
                        className="block text-sm font-medium text-white"
                      >
                        Password <span className="text-red-500">*</span>
                      </label>
                      <div className="mt-1 relative">
                        <input
                          value={confirmValues.password}
                          onChange={e =>
                            setConfirmValues({ ...confirmValues, password: e.target.value })
                          }
                          type={showPassword ? 'text' : 'password'}
                          required
                          className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded shadow-sm appearance-none focus:border-gray-500 focus:outline-none focus:ring-gray-500 sm:text-sm"
                        />
                        <button
                          type="button"
                          onClick={togglePasswordVisibility}
                          className="absolute inset-y-0 right-0 flex items-center pr-3"
                        >
                          {showPassword ? (
                            <EyeSlashIcon className="h-5 w-5 text-neutral-500" aria-hidden="true" />
                          ) : (
                            <EyeIcon className="h-5 w-5 text-neutral-500" aria-hidden="true" />
                          )}
                        </button>
                      </div>
                    </div>
                    <div>
                      <label
                        htmlFor="password"
                        className="block text-sm font-medium text-white"
                      >
                        Confirm Password <span className="text-red-500">*</span>
                      </label>
                      <div className="mt-1 relative">
                        <input
                          value={confirmValues.confirmPassword}
                          onChange={e =>
                            setConfirmValues({
                              ...confirmValues,
                              confirmPassword: e.target.value
                            })
                          }
                          type={showPassword ? 'text' : 'password'}
                          required
                          className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded shadow-sm appearance-none focus:border-gray-500 focus:outline-none focus:ring-gray-500 sm:text-sm"
                        />
                        <button
                          type="button"
                          onClick={togglePasswordVisibility}
                          className="absolute inset-y-0 right-0 flex items-center pr-3"
                        >
                          {showPassword ? (
                            <EyeSlashIcon className="h-5 w-5 text-neutral-500" aria-hidden="true" />
                          ) : (
                            <EyeIcon className="h-5 w-5 text-neutral-500" aria-hidden="true" />
                          )}
                        </button>
                      </div>
                    </div>
                  </div>

                  <div>
                    <button
                      onClick={() => onConfirm()}
                      type="button"
                      disabled={
                        !confirmValues.confirmationCode ||
                        !confirmValues.password ||
                        confirmValues.password != confirmValues.confirmPassword
                      }
                      className="flex justify-center w-full px-4 py-2 font-semibold text-white bg-purple-500 border border-transparent rounded shadow-sm disabled:bg-gray-500 text-md hover:bg-purple-600 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                    >
                      Confirm
                    </button>
                  </div>
                </div>}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
