import { record } from "aws-amplify/analytics";
import { useLocation } from "react-router-dom";

export const SidebarLink = ({
  text,
  path,
  navigate,
  icon,
  sidebarOpen,
  customAction,
}) => {
  const location = useLocation();
  const user = JSON.parse(localStorage.getItem("user"));

  return (
    <div
      className={`${location.pathname === path ? "bg-purple-500" : "hover:bg-neutral-600"}
        relative
        text-white
        text-2xl
        rounded
        p-2
        flex
        flex-row
        gap-2
        cursor-pointer
        items-center
        overflow-hidden
        transition-all
        duration-500
        mb-2
        ${sidebarOpen ? "max-w-52" : "max-w-10"}
        `}
      onClick={() => {
        if (!navigate) {
          console.warn("need to pass a navigator into sidebarlink");
          return;
        }
        if (path === "/about") {
          record({
            name: "clickAbout",
            attributes: {
              userId: user?.id,
            },
          });
        }
        if (customAction) {
          customAction();
          return;
        }
        navigate(path);
      }}
    >
      {icon()}
      <span
        className={`
          absolute
          left-10
          transition-all
          duration-500
          font-medium
          text-white
          cursor-pointer
          select-none
          text-base
          origin-left
          w-40
          ${sidebarOpen ? "opacity-100" : "opacity-0"}`}
      >
        {text}
      </span>
    </div>
  );
};
