/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:4bd03f06-4162-437e-93c0-8c0c2d4d7c5f",
    "aws_cognito_region": "us-east-1",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_types": [],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [],
    "aws_mobile_analytics_app_id": "78226ef31694461ba71c584570376a53",
    "aws_mobile_analytics_app_region": "us-east-1",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "78226ef31694461ba71c584570376a53",
            "region": "us-east-1"
        }
    },
    "Auth": {
        "region": "us-east-1",
        "userPoolId": "us-east-1_9MSsIiRJU",
        "userPoolWebClientId": "2kgoa22g7fa6uqv92kjkkrl61c",
        "Cognito": {
            "identityPoolId": "us-east-1:4bd03f06-4162-437e-93c0-8c0c2d4d7c5f",
            "allowGuestAccess": true
        },
        "oauth": {}
    }
};


export default awsmobile;