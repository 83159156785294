export const getUserGeolocation = () => {
    return new Promise((resolve, reject) => {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    // Here you can use latitude and longitude to fetch country, etc.
                    resolve(`${latitude},${longitude}`);
                },
                (error) => {
                    console.error('Error getting geolocation:', error);
                    resolve('unknown');  // In case geolocation fails
                }
            );
        } else {
            resolve('unknown');  // Geolocation is not supported by the browser
        }
    });
};